<template>
  <v-sheet class="ma-4" flat>
    <!-- Start Header Info -->
    <v-row :class="!darkmode && 'white'" class="pa-4 mb-5">
      <v-col v-if="roleType != 'STUDENT'" cols="12">
        <div class="font-weight-bold" style="cursor: pointer; width: 100px">
          <v-btn @click="$router.go(-1)" depressed>
            <v-icon>mdi-arrow-left</v-icon>
            {{ $t("app.back") }}
          </v-btn>
        </div>
        <v-divider class="my-5" />
      </v-col>
      <v-col
        :class="!$vuetify.breakpoint.xsOnly && 'mt-3 divider'"
        cols="12"
        sm="4"
      >
        <div>{{ $t("app.name") }}</div>
        <div class="font-weight-bold subtitle">{{ profileStudent.name }}</div>
      </v-col>
      <v-col
        :class="$vuetify.breakpoint.xsOnly ? 'mt-5' : 'mt-2 divider'"
        cols="12"
        sm="3"
      >
        <div>{{ $t("master_data.student.nomor") }}</div>
        <div class="font-weight-bold subtitle">{{ profileStudent.nis }}</div>
      </v-col>
      <v-col
        :class="$vuetify.breakpoint.xsOnly ? 'mt-5' : 'mt-3 divider'"
        cols="12"
        sm="4"
      >
        <div>{{ $t("app.class") }}</div>
        <div class="font-weight-bold subtitle">{{ profileStudent.class }}</div>
      </v-col>
      <v-col
        v-if="userProfile"
        :class="$vuetify.breakpoint.xsOnly ? 'mt-5' : 'mt-3 divider'"
        cols="12"
        sm="4"
      >
        <div>{{ $t("dashboard.phone") }}</div>
        <div class="font-weight-bold subtitle">
          {{ userProfile.phone || "-" }}
        </div>
      </v-col>
      <template v-if="userProfile">
        <v-col
          :class="$vuetify.breakpoint.xsOnly ? 'mt-5' : 'mt-3'"
          cols="12"
          sm="8"
          md="8"
        >
          <div>{{ $t("dashboard.address") }}</div>
          <div class="font-weight-bold subtitle">{{ userProfile.address }}</div>
        </v-col>
        <!-- <v-col
          :class="$vuetify.breakpoint.xsOnly ? 'mt-5' : 'mt-3'"
          cols="12"
          sm="12"
          md="4"
        >
          <v-row no-gutters align="center">
            <v-spacer></v-spacer>
            <v-btn
              :loading="loadingDownload"
              class="gradient-primary caption"
              dark
              depressed
            >
              <span v-if="$vuetify.breakpoint.smAndUp" class="mr-2">{{
                $t("app.download")
              }}</span>
              <v-icon>mdi-cloud-download</v-icon>
            </v-btn>
          </v-row>
        </v-col> -->
      </template>
    </v-row>

    <v-card
      v-if="homeroom"
      class="my-5 pa-4"
      :class="!darkmode && 'white'"
      flat
    >
      <v-row no-gutters>
        {{ $t("dashboard.homeroom_teacher") }}
      </v-row>
      <v-divider class="my-2"></v-divider>
      <v-row no-gutters v-if="homeroom.person">
        <v-col class="divider">
          <div class="font-weight-bold">
            {{ $t("app.name") }}
          </div>
          <div>{{ homeroom.person.name || "-" }}</div>
        </v-col>
        <v-col class="divider px-2">
          <div class="font-weight-bold">
            Email
          </div>
          <div>{{ homeroom.person.email || "-" }}</div>
        </v-col>
        <v-col class="px-2">
          <div class="font-weight-bold">
            {{ $t("dashboard.phone") }}
          </div>
          <div>{{ homeroom.person.phone || "-" }}</div>
        </v-col>
      </v-row>
    </v-card>

    <!-- End Header Info -->

    <!-- Start Section 1 -->
    <v-row>
      <v-col :class="$vuetify.breakpoint.xsOnly && 'mb-5'" cols="12" sm="4">
        <v-card :class="!darkmode && 'white'" class="pa-5 elevation-0">
          <div class="font-weight-bold mb-3">
            {{ $t("master_data.student.profile_photo") }}
          </div>
          <v-card class="rounded-lg elevation-0">
            <v-img :src="profileStudent.photo" max-height="400" contain />
          </v-card>
        </v-card>
      </v-col>
      <v-col cols="12" sm="8">
        <v-card :class="!darkmode && 'white'" class="pa-5 elevation-0">
          <div class="font-weight-bold mb-3">
            {{ $t("master_data.student.value_indicator") }}
          </div>
          <v-card class="rounded-lg elevation-0">
            <v-data-table
              :headers="indicatorHeader"
              :items="dataTableIndicator"
              hide-default-footer
              class="elevation-0"
              disable-pagination
            >
              <template #item.indicator="{ item }">
                <v-avatar :color="item.color" class="ma-3">
                  <v-icon color="white">{{ item.icon }}</v-icon>
                </v-avatar>
              </template>
            </v-data-table>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
    <!-- End Section 1 -->

    <!-- Star Section 2 -->
    <v-row class="my-5">
      <v-col :class="$vuetify.breakpoint.xsOnly && 'mb-5'" cols="12" md="6">
        <v-card :class="!darkmode && 'white'" class="pa-5 elevation-0">
          {{
            roleType === "STUDENT"
              ? $t("master_data.student.cart.student_average_value")
              : $t("master_data.student.indicator_table.average") +
                " " +
                profileStudent.name
          }}
          <v-divider class="my-3" />
          <v-row>
            <v-col cols="12" sm="9">
              <BarChart
                v-if="!loading"
                :labels="labelsChart"
                :datasets="datasets.averageStudent"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <div class="my-3">
                <span class="font-weight-bold">
                  {{ $t("master_data.student.cart.best") }} :
                </span>
                <div class="d-flex flex-column caption">
                  <span v-if="averageStudent.good.length === 0">-</span>
                  <span
                    v-else
                    v-for="(item, index) in averageStudent.good"
                    :key="item.id_lesson"
                  >
                    {{ (index += 1) }}. {{ item.lesson }}
                  </span>
                </div>
              </div>
              <div>
                <span class="font-weight-bold">
                  {{ $t("master_data.student.cart.bad") }} :
                </span>
                <div class="d-flex flex-column caption">
                  <span v-if="averageStudent.lessGood.length === 0">-</span>
                  <span
                    v-else
                    v-for="(item, index) in averageStudent.lessGood"
                    :key="item.id_lesson"
                  >
                    {{ (index += 1) }}. {{ item.lesson }}
                  </span>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-card :class="!darkmode && 'white'" class="pa-5 elevation-0">
          <div class="d-flex">
            {{
              roleType === "STUDENT"
                ? $t("master_data.student.cart.versus")
                : $t("master_data.student.indicator_table.average") +
                  " " +
                  profileStudent.name +
                  " VS " +
                  $t("master_data.student.class_avarage")
            }}
          </div>
          <v-divider class="my-3" />
          <v-row>
            <v-col cols="12" sm="9">
              <BarChart
                v-if="!loading"
                :labels="labelsChart"
                :datasets="datasets.averageVersus"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <div class="my-3">
                <span class="font-weight-bold">
                  {{ $t("master_data.student.cart.above_average") }} :
                </span>
                <div class="d-flex flex-column caption">
                  <span v-if="averageVersus.above.length === 0">-</span>
                  <span
                    v-else
                    v-for="(item, index) in averageVersus.above"
                    :key="index"
                  >
                    {{ (index += 1) }}. {{ item }}
                  </span>
                </div>
              </div>
              <div>
                <span class="font-weight-bold">
                  {{ $t("master_data.student.cart.below_average") }} :
                </span>
                <div class="d-flex flex-column caption">
                  <span v-if="averageVersus.below.length === 0">-</span>
                  <span
                    v-else
                    v-for="(item, index) in averageVersus.below"
                    :key="index"
                  >
                    {{ (index += 1) }}. {{ item }}
                  </span>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!-- End Section 2 -->

    <!-- Start Section 3 -->
    <v-row class="mb-5">
      <v-col
        :class="$vuetify.breakpoint.xsOnly && 'mb-5'"
        cols="12"
        sm="12"
        md="6"
      >
        <v-card :class="!darkmode && 'white'" class="pa-5 elevation-0">
          <v-data-table
            :headers="tableHeader"
            :items="dataTable"
            :loading="loading"
            disable-pagination
            fixed-header
            height="450"
            hide-default-footer
            class="elevation-0"
          >
            <template #item.indicator="{ item }">
              <v-avatar :color="item.color" class="ma-1">
                <v-icon color="white">{{ item.icon }}</v-icon>
              </v-avatar>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-card :class="!darkmode && 'white'" class="pa-5 elevation-0">
          <div class="font-weight-bold mb-5 d-flex align-center">
            <span>
              {{ $t("master_data.student.value_grafic") }}
            </span>
            <v-spacer />
            <v-select
              :items="profileStudent.score"
              :label="$t('master_data.student.select_lesson')"
              item-text="lesson"
              item-value="id_lesson"
              hide-details
              outlined
              dense
              class="caption select-175"
              @change="changeLesson"
            />
          </div>
          <v-card class="rounded-lg elevation-0">
            <LineChart :chart-data="lineChart" />
          </v-card>
        </v-card>
      </v-col>
    </v-row>
    <!-- End Section 3 -->
  </v-sheet>
</template>

<script>
import { studentProfiling } from "@/api/admin/master/student";
import { isExternal } from "@/utils/validate";
import { mapGetters } from "vuex";

export default {
  metaInfo: {
    title: "Student Profile",
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  props: {
    backButton: Boolean,
    userProfile: Object,
    homeroom: Object
  },
  computed: {
    darkmode() {
      return this.$store.getters.g_darkmode;
    },
    ...mapGetters(["g_user"])
  },
  components: {
    BarChart: () => import("@/components/Chart/BarChart"),
    LineChart: () => import("@/components/Chart/LineChart")
  },
  created() {
    this.getData();
  },
  data() {
    return {
      roleType: this.$store.getters.g_role_type,
      boyPhoto: require("@/assets/svg/boy.svg"),
      girlPhoto: require("@/assets/svg/girl.svg"),
      table: {
        page: 1,
        limit: 10
      },
      lineChart: {
        labels: [],
        datasets: []
      },
      datasets: {
        averageStudent: null,
        averageVersus: null
      },
      averageStudent: {
        good: [],
        lessGood: []
      },
      averageVersus: {
        above: [],
        below: []
      },
      labelsChart: [],
      dataTableIndicator: [
        {
          color: "#1B5E20",
          desc: this.$i18n.t("master_data.student.indicator_table.very_good"),
          icon: "mdi-arrow-up"
        },
        {
          color: "#43A047",
          desc: this.$i18n.t("master_data.student.indicator_table.good"),
          icon: "mdi-arrow-top-right"
        },
        {
          color: "yellow",
          desc: this.$i18n.t("master_data.student.indicator_table.average"),
          icon: "mdi-arrow-right"
        },
        {
          color: "amber darken-2",
          desc: this.$i18n.t("master_data.student.indicator_table.less"),
          icon: "mdi-arrow-bottom-right"
        },
        {
          color: "orange darken-4",
          desc: this.$i18n.t("master_data.student.indicator_table.very_less"),
          icon: "mdi-arrow-down"
        }
      ],
      dataTable: [],
      valueName: {
        up: {
          color: "#1B5E20",
          icon: "mdi-arrow-up"
        },
        topRight: {
          color: "#43A047",
          icon: "mdi-arrow-top-right"
        },
        right: {
          color: "yellow",
          icon: "mdi-arrow-right"
        },
        bottomRight: {
          color: "amber darken-2",
          icon: "mdi-arrow-bottom-right"
        },
        down: {
          color: "orange darken-4",
          icon: "mdi-arrow-down"
        }
      },
      loadingDownload: false,
      loadingDataTable: false,
      indicatorHeader: [
        {
          text: this.$i18n.t("master_data.student.value_indicator"),
          value: "indicator",
          sortable: false
        },
        {
          text: this.$i18n.t("master_data.table.description"),
          value: "desc",
          sortable: false
        }
      ],
      tableHeader: [
        {
          text: this.$i18n.t("app.subjects"),
          value: "lesson",
          sortable: false
        },
        {
          text: this.$i18n.t("master_data.student.class_avarage"),
          value: "avg_class",
          sortable: false
        },
        {
          text: this.$i18n.t("master_data.student.indicator_table.average"),
          value: "avg_score",
          sortable: false
        },
        {
          text: this.$i18n.t("master_data.student.last_point"),
          value: "latest_score",
          sortable: false
        },
        {
          text: this.$i18n.t("master_data.student.value_indicator"),
          value: "indicator",
          sortable: false
        }
      ],
      profileStudent: {},
      loading: false
    };
  },
  methods: {
    changeLesson(idLesson) {
      const dataLineChart = this.profileStudent.score.find(
        item => item.id_lesson === idLesson
      );
      const labelsChart = [];
      const score = [];
      const kkm = [];
      if (dataLineChart) {
        dataLineChart.grafik_score.map(item => {
          labelsChart.push(item.title);
          score.push(item.score);
          kkm.push(item.kkm);
        });
      }
      this.lineChart = {
        labels: labelsChart,
        datasets: [
          {
            label: this.$i18n.t("master_data.student.cart.student_value"),
            backgroundColor: "rgb(16, 137, 155, 0.3)",
            pointBackgroundColor: "white",
            pointBorderColor: "#06C8DD",
            borderColor: "#3A9EDD",
            borderWidth: 1,
            data: score
          },
          {
            label: "KKM",
            backgroundColor: "rgb(196, 33, 36, 0.3)",
            pointBackgroundColor: "white",
            pointBorderColor: "#DB3F42",
            borderColor: "#DB3F42",
            borderWidth: 1,
            data: kkm
          }
        ]
      };
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    getValueIndicator(indicator) {
      let result = "";
      switch (indicator.toUpperCase()) {
        case "VERY GOOD":
          result = this.valueName.up;
          break;
        case "GOOD":
          result = this.valueName.topRight;
          break;
        case "AVERAGE":
          result = this.valueName.right;
          break;
        case "LESS":
          result = this.valueName.bottomRight;
          break;
        case "VERY LESS":
          result = this.valueName.down;
          break;
        default:
          result = this.valueName.down;
          break;
      }
      return result;
    },
    async getData() {
      this.loading = true;
      try {
        let response = [];
        if (this.roleType === "STUDENT") {
          response = await studentProfiling({ person: this.g_user.person });
        } else {
          let person = this.$route.query.person;
          if (person) {
            response = await studentProfiling({ person: person });
          } else {
            this.snackBar(false, this.$i18n.t("app.there_is_an_error"));
          }
        }
        if (response.data.code) {
          const data = response.data.data;
          const studentAverage = data.score.map(item => Number(item.avg_score));
          this.profileStudent = data;
          const profileStudent = JSON.parse(JSON.stringify(data));

          // get average student
          this.averageStudent.good = profileStudent.score
            .sort((x, y) => Number(y.avg_score) - Number(x.avg_score))
            .slice(0, 2);
          this.averageStudent.lessGood = profileStudent.score
            .sort((x, y) => Number(x.avg_score) - Number(y.avg_score))
            .slice(0, 2);

          // handle image empty
          if (!isExternal(data.photo)) {
            switch (this.$store.getters.g_user.gender) {
              case "L":
                data.photo = this.boyPhoto;
                break;
              case "P":
                data.photo = this.girlPhoto;
                break;
              default:
                data.photo = this.boyPhoto;
                break;
            }
          }
          this.labelsChart = data.score.map(item => item.lesson);

          // Student Average Value
          this.datasets.averageStudent = [
            {
              label: this.$i18n.t("app.score"),
              backgroundColor: "#3A9EDD",
              pointBackgroundColor: "white",
              pointBorderColor: "#249EBF",
              data: studentAverage
            }
          ];

          // Student Average vs Class Average
          data.score.map(item => {
            const avg_class = Number(item.avg_class);
            const avg_score = Number(item.avg_score);
            avg_score > avg_class
              ? this.averageVersus.above.push(item.lesson)
              : this.averageVersus.below.push(item.lesson);
          });
          this.datasets.averageVersus = [
            {
              label: this.$i18n.t("master_data.student.cart.student_value"),
              backgroundColor: "#FFBA4A",
              pointBackgroundColor: "white",
              pointBorderColor: "#249EBF",
              data: studentAverage
            },
            {
              label: this.$i18n.t(
                "master_data.student.cart.student_average_class"
              ),
              backgroundColor: "#385DA8",
              pointBackgroundColor: "white",
              pointBorderColor: "#249EBF",
              data: data.score.map(item => Number(item.avg_class))
            }
          ];

          // Datatable
          this.dataTable = data.score.map(
            item =>
              (item = {
                ...item,
                ...this.getValueIndicator(item.score_indicator)
              })
          );
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        console.error("getData()", error);
      }
      this.loading = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.divider {
  border-right: 1px solid #e2e2e2;
}
.color-info {
  border-radius: 7px;
  border: 1px solid lightgray;
  width: 20px;
  height: 20px;
}
</style>
